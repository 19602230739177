import React, { useState, useRef, useEffect } from "react"
import { Form, Button, Row, Col, Tooltip } from "reactstrap"
import Layout from "../../../components/layout/layout"
import Content from "../../../components/content/content"
import Header from "../../../components/support/header/header"
import Footer from "../../../components/support/footer/footer"
import { useUserOrgs } from "../../../hooks/use-user"
import { Loading } from "../../../components/loading"
import { fetch_post } from "../../../utils/fetch"
import { toast } from "react-toastify"
import * as Sentry from "@sentry/gatsby"
import { useAuth0 } from "@auth0/auth0-react"
import SearchHeader from "../../../components/support/input-search/users"
import UserView from "../../../components/support/user/user-view"
import SuccessModal from "../../../components/support/modal/jump-to-servicedesk"
import SuperAdminHeader from "../../../components/support/superadmin-header"
import _ from "lodash"

const SUCCESS = 0
const ERROR = 1

const classMap = {
  Active: "text-primary",
  Inactive: "text-black-50",
}

const UpdateUserStatus = props => {
  const {
    loading,
    currentOrg: {
      index,
      name,
      role: { isSuperAdmin },
    },
  } = useUserOrgs()
  const { getAccessTokenSilently } = useAuth0()
  const [selectUser, setSelectUser] = useState(null)
  const [savingData, setSavingData] = useState(false)
  const [ticketLink, setTicketLink] = useState("")
  const [buttonTooltip, setButtonTooltip] = useState("")
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const toggle = () => setTooltipOpen(!tooltipOpen)
  const [orgLoading, setOrgLoading] = useState(true)
  const [parentOrganization, setParentOrganization] = useState("")
  const [raiseOnBehalfOf, setRaiseOnBehalfOf] = useState(null)
  const childRef = useRef()

  useEffect(() => {
    if (!loading && !isSuperAdmin) {
      setOrgLoading(false)
    }
  }, [loading, isSuperAdmin])

  const getStatus = () => {
    const { pathname } = props.location
    if (pathname.startsWith("/inner-support/enable-user")) {
      return "enable"
    } else if (pathname.startsWith("/inner-support/disable-user")) {
      return "disable"
    } else if (pathname.startsWith("/inner-support/remove-user")) {
      return "remove"
    } else {
      return ""
    }
  }

  const status = getStatus()
  useEffect(() => {
    if (selectUser) {
      if (status === "enable" && selectUser.active === "active") {
        setButtonTooltip("The specified user has already been activated.")
      } else if (status === "disable" && selectUser.active === "inactive") {
        setButtonTooltip("The specified user has already been deactivated.")
      } else {
        setButtonTooltip("")
      }
    }
  }, [selectUser, status])

  if (loading) {
    return <Loading />
  }

  async function send_request() {
    // Build up a list of patches
    let patches = { status }
    if (!index) {
      patches = { ...patches, parentOrganization }
    }
    return getAccessTokenSilently()
      .then(accessToken =>
        fetch_post(
          _.isNil(index)
            ? `ui/support/users/${selectUser.username}/status`
            : `ui/support/users/${selectUser.username}/status?index=${index}`,
          patches,
          accessToken
        )
      )
      .then(response => {
        return { status: SUCCESS, description: response.status.description, data: response.data }
      })
      .catch(error => {
        Sentry.captureException(error)
        console.log(
          `Error while ${status.substring(0, status.length - 1)}ing user from ${JSON.stringify(
            patches
          )}: ${error}`
        )
        return { status: ERROR, description: error }
      })
  }

  const handleSubmit = event => {
    event.preventDefault()
    setSavingData(true)
    send_request().then(resultCode => {
      console.log(resultCode, "resultCoderesultCode")
      if (resultCode.status === 0) {
        setTicketLink(resultCode.data._links.web)
        childRef.current.toggle()
      } else {
        toast(`${resultCode.description}`, {
          type: "error",
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      }
      setSavingData(false)
    })
  }

  return (
    <Layout pageTitle={`${_.startCase(status)} User`}>
      <Header>{`${_.startCase(status)} User`}</Header>
      <Content>
        {isSuperAdmin && (
          <SuperAdminHeader
            setOrgLoading={load => setOrgLoading(load)}
            parentOrganization={parentOrganization}
            setParentOrganization={parentOrg => setParentOrganization(parentOrg)}
            // setRaiseOnBehalfOf={user => setRaiseOnBehalfOf(user)}
          />
        )}
        {!orgLoading && (
          <>
            <Row className="justify-content-center mb-2">
              <Col xs={{ size: 8 }} className="d-flex justify-content-center align-items-center">
                <SearchHeader
                  selectData={data => setSelectUser(data)}
                  index={index}
                  parentOrganization={parentOrganization}
                />
              </Col>
            </Row>
            {selectUser ? (
              <>
                <Row className="mb-2">
                  <UserView
                    user={selectUser}
                    mode="v"
                    orgIndex={index}
                    isSuperAdmin={isSuperAdmin}
                    selectParentOrg={name}
                  />
                  <div style={{ width: "100%" }}>
                    <Row className="mb-12">
                      <Col xm={6} sm={6} md={6} className="text-right">
                        Account Status:
                      </Col>
                      <Col className={classMap[_.startCase(selectUser.active)]}>
                        {_.startCase(selectUser.active)}
                      </Col>
                    </Row>
                  </div>
                </Row>
                <Form method="POST" onSubmit={handleSubmit} className="justify-content-center">
                  <Row className="justify-content-center mb-2" id="wrapper">
                    <Button
                      type="button"
                      onClick={handleSubmit}
                      disabled={savingData || buttonTooltip || (!index && !parentOrganization)}
                      className="mr-2"
                      color={"primary"}
                      style={{ padding: 0 }}
                    >
                      {savingData ? (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        >
                          <span className="sr-only">Saving...</span>
                        </span>
                      ) : (
                        <div id="submit" style={{ padding: "0.375rem 0.75rem" }}>
                          {`${_.startCase(status)} user`}
                        </div>
                      )}
                    </Button>
                    <Tooltip
                      placement="top"
                      isOpen={buttonTooltip && tooltipOpen}
                      autohide={false}
                      container="wrapper"
                      target="submit"
                      toggle={toggle}
                    >
                      {buttonTooltip}
                    </Tooltip>
                  </Row>
                </Form>
              </>
            ) : null}
            <SuccessModal ticketLink={ticketLink} cRef={childRef} />
            <Footer />
          </>
        )}
      </Content>
    </Layout>
  )
}

export default UpdateUserStatus
